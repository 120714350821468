/*
 * SPDX-FileCopyrightText: 2024 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */
.preview {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    width: auto;
}
