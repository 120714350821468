/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.frame {
  color-scheme: initial;
}

@media print {
  .frame {
    height: auto !important;
  }
}
