/*!
 * SPDX-FileCopyrightText: 2022 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.code-highlighter {
  position: relative;

  :global(code.hljs) {
    overflow-x: auto;
    background-color: rgba(27, 31, 35, .05);
    padding: 16px;
    display: grid !important;
    grid-template-columns: auto minmax(0, 1fr);

    :global(body[data-bs-theme=dark]) & {
      background-color: rgb(27, 31, 35);
    }

    .codeline {
      grid-column: 2;
      white-space: pre;
    }

    .linenumber {
      grid-column: 1;
      position: relative;
      cursor: default;
      z-index: 4;
      padding: 0 8px 0 0;
      min-width: 20px;
      box-sizing: content-box;
      color: #afafaf;
      border-right: 3px solid #6ce26c;
      flex-direction: column;
      overflow: hidden;
      user-select: none;
      align-items: flex-end;
      display: none;
    }

    &.showGutter {
      .linenumber {
        display: flex;
      }

      .codeline {
        margin: 0 0 0 16px;
      }
    }

    &.wrapLines .codeline {
      white-space: pre-wrap;
    }
  }
}

@media print {
  .code-highlighter {
    border: black solid 1px;
    border-radius: var(--bs-border-radius);
  }
}
